import Parse from "parse"
import axios from 'axios'

//Initialize Parse
Parse.initialize(process.env.GATSBY_PARSE_APP_ID, process.env.GATSBY_PARSE_JS_KEY)
Parse.serverURL = "https://parseapi.back4app.com"


//USERS
export async function LogOut() {
    Parse.User.logOut();
}

export async function ResetPassword(email) {
    
    try {
        await Parse.User.requestPasswordReset(email)
        return {
            success: true
        }
    }catch(error) {
        console.log("Error: " + error.code + " " + error.message);
        return { 
            success: false,
            error: error.message
        }
    }
}

export async function LoginUser(email, password) {
    
    try {
        
        //Log out any currently active user
        await Parse.User.logOut()
        
        const user = await Parse.User.logIn(email, password)
        let userInfo = {
            id: user.id,
            firstName: user.get("firstName"),
            lastName: user.get("lastName"),
            email: user.get("email"),
            phoneNumber: user.get("phone"),
        }
        return {
            success: true,
            userInfo,
        }
    } catch (error) {
        console.log(error)
        return {
            success: false,
            error: error.message,
        }
    }
}

export async function SignupUser(email, password, phone, firstName, lastName) {

    //Log out any currently active user
    await Parse.User.logOut()

    var user = new Parse.User()
    user.set("username", email)
    user.set("password", password)
    user.set("email", email)

    // other fields can be set just like with Parse.Object
    user.set("phone", phone)
    user.set("firstName", firstName)
    user.set("lastName", lastName)

    try {
        const newUser = await user.signUp()
        return {
            success: true,
            user: newUser,
        }
        // Hooray! Let them use the app now.
    } catch (error) {
        console.log(error)
        return {
            success: false,
            error: error.message,
        }
    }
}

export async function GetCurrentUser() {

    // Parse.User.logOut().then(() => {
    // });

    var currentUser = Parse.User.current()
    if (currentUser) {
        let userInfo = {
            id: currentUser.id,
            firstName: currentUser.get("firstName"),
            lastName: currentUser.get("lastName"),
            email: currentUser.get("email"),
            phoneNumber: currentUser.get("phone"),
            customerId: currentUser.get("customerId")
        }
        return {
            success: true,
            userInfo,
        }
    } else {
        return {
            success: false,
        }
    }
}

export async function UpdateCustomerId(customerId) {
    var currentUser = Parse.User.current()
    if (currentUser) {
        
        currentUser.set("customerId", customerId)
        await currentUser.save()
        return {
            success: true,
        }
    } else {
        return {
            success: false,
        }
    }
}

//TRADE

export async function GetDevices() {
    const Devices = Parse.Object.extend("Devices")
    const query = new Parse.Query(Devices)

    query.ascending("createdAt")

    try {

        let devices = []

        const results = await query.find()

        for (let i=0; i<results.length; i++) {

            let device = results[i]

            let deviceObj = {
                id: device.id,
                name: device.get("name"),
                maxPrice: device.get("maxPrice"),
                storageCapacities: device.get("storageCapacities"),
                batteryReduction: device.get("batteryReduction"),
                hasFaceId: device.get("hasFaceId"),
                earSpeakerReduction: device.get("earSpeakerReduction"),
                loudSpeakerReduction: device.get("loudSpeakerReduction"),
                frontCameraReduction: device.get("frontCameraReduction"),
                backCameraReduction: device.get("backCameraReduction"),
                goodBodyConditionReduction: device.get(
                    "goodBodyConditionReduction"
                ),
                crackedScreenReduction: device.get("crackedScreenReduction"),
                backGlassCrackedReduction: device.get("backGlassCrackedReduction"),
                sixteenReduction: device.get("sixteenReduction"),
                thirtyTwoReduction: device.get("thirtyTwoReduction"),
                sixtyFourReduction: device.get("sixtyFourReduction"),
                oneTwentyEightReduction: device.get("oneTwentyEightReduction"),
                twoFiftySixReduction: device.get("twoFiftySixReduction"),
                fiveTwelveReduction: device.get("fiveTwelveReduction"),
            }

            devices.push(deviceObj)
        }

        return {
            success: true,
            devices
        }
    }catch(err) {
        console.log(err)
        await Parse.User.logOut()

        return {
            success: false,
            error: `${err.message}. Please refresh the page`
        }
    }
}

export async function GetDeviceById(id) {
    const Devices = Parse.Object.extend("Devices")
    const query = new Parse.Query(Devices)
    query.equalTo("objectId", id)

    try {
        const device = await query.first()

        //Create device object
        const deviceObj = {
            name: device.get("name"),
            maxPrice: device.get("maxPrice"),
            storageCapacities: device.get("storageCapacities"),
            batteryReduction: device.get("batteryReduction"),
            hasFaceId: device.get("hasFaceId"),
            earSpeakerReduction: device.get("earSpeakerReduction"),
            loudSpeakerReduction: device.get("loudSpeakerReduction"),
            frontCameraReduction: device.get("frontCameraReduction"),
            backCameraReduction: device.get("backCameraReduction"),
            goodBodyConditionReduction: device.get(
                "goodBodyConditionReduction"
            ),
            crackedScreenReduction: device.get("crackedScreenReduction"),
            sixteenReduction: device.get("sixteenReduction"),
            thirtyTwoReduction: device.get("thirtyTwoReduction"),
            sixtyFourReduction: device.get("sixtyFourReduction"),
            oneTwentyEightReduction: device.get("oneTwentyEightReduction"),
            twoFiftySixReduction: device.get("twoFiftySixReduction"),
            fiveTwelveReduction: device.get("fiveTwelveReduction"),
        }
        return {
            success: true,
            deviceObj,
        }
    } catch (err) {
        return { success: false, err }
    }
}

export async function UploadDeviceForTrade(
    frontImage,
    backImage,
    leftSideImage,
    rightSideImage,
    fullName,
    email,
    phoneNumber,
    description,
    userIntent,
    deviceName,
    desiredDevice,
    storage,
    biometrics,
    homeButton,
    batteryHealth,
    loudSpeaker,
    earSpeaker,
    frontCamera,
    backCamera,
    bodyCondition,
    offer,
    screenCracked = false,
    backGlassCracked = false
) {
    const Trade = Parse.Object.extend("Trade")
    const trade = new Trade()

    if (frontImage) {
        let parseFile = new Parse.File("frontImage.png", frontImage)
        trade.set("frontImage", parseFile)
    }

    if (backImage) {
        let parseFile = new Parse.File("backImage.png", backImage)
        trade.set("backImage", parseFile)
    }

    if (leftSideImage) {
        let parseFile = new Parse.File("leftSideImage.png", leftSideImage)
        trade.set("leftSideImage", parseFile)
    }

    if (rightSideImage) {
        let parseFile = new Parse.File("rightSideImage.png", rightSideImage)
        trade.set("rightSideImage", parseFile)
    }

    //Contact Information
    trade.set("fullName", fullName)
    trade.set("email", email)
    trade.set("phoneNumber", phoneNumber)
    trade.set("description", description)

    //Device Information
    trade.set("userIntent", userIntent)
    trade.set("deviceName", deviceName)
    trade.set("desiredDevice", desiredDevice)
    trade.set("storage", storage)
    trade.set("biometrics", biometrics)
    trade.set("homeButton", homeButton)
    trade.set("batteryHealth", batteryHealth)
    trade.set("loudSpeaker", loudSpeaker)
    trade.set("earSpeaker", earSpeaker)
    trade.set("frontCamera", frontCamera)
    trade.set("backCamera", backCamera)
    trade.set("bodyCondition", bodyCondition)
    trade.set("screenCracked", screenCracked)
    trade.set("backGlassCracked", backGlassCracked)
    trade.set("offer", offer)

    //Defaults
    trade.set("status", "New")
    trade.set("isArchive", false)

    try {
        const createdTrade = await trade.save()

        //Return the Order ID
        return {
            success: true,
            orderID: createdTrade.id,
        }
    } catch (error) {
        return {
            success: false,
            error: error.message,
        }
    }
}

export async function UploadDeviceForSale(
    fullName,
    email,
    phoneNumber,
    userIntent,
    deviceName,
    storage,
    biometrics,
    homeButton,
    batteryHealth,
    loudSpeaker,
    earSpeaker,
    frontCamera,
    backCamera,
    bodyCondition,
    screenCracked,
    offer
) {
    const Trade = Parse.Object.extend("Trade")
    const trade = new Trade()

    //Contact Information
    trade.set("fullName", fullName)
    trade.set("email", email)
    trade.set("phoneNumber", phoneNumber)

    //Device Information
    trade.set("userIntent", userIntent)
    trade.set("deviceName", deviceName)
    trade.set("storage", storage)
    trade.set("biometrics", biometrics)
    trade.set("homeButton", homeButton)
    trade.set("batteryHealth", batteryHealth)
    trade.set("loudSpeaker", loudSpeaker)
    trade.set("earSpeaker", earSpeaker)
    trade.set("frontCamera", frontCamera)
    trade.set("backCamera", backCamera)
    trade.set("bodyCondition", bodyCondition)
    trade.set("screenCracked", screenCracked ? screenCracked : false)
    trade.set("offer", offer)

    //Defaults
    trade.set("status", "New")
    trade.set("isArchive", false)

    try {
        const createdTrade = await trade.save()

        //Return the Order ID
        return {
            success: true,
            orderID: createdTrade.id,
        }
    } catch (error) {
        return {
            success: false,
            error: error.message,
        }
    }
}

//CHECKOUT

export async function CreateOrderInParse(
    fullName,
    email,
    phoneNumber,
    total,
    draftOrderId,
) {
    const Order = Parse.Object.extend("Order")
    const order = new Order()

    order.set("fullName", fullName)
    order.set("email", email)
    order.set("phoneNumber", phoneNumber)
    order.set("orderTotal", total)
    order.set("orderId", draftOrderId)
    order.set("paid", false)

    try {
        const createdOrder = await order.save()

        //Return the Order ID
        return {
            success: true,
            orderID: createdOrder.id,
        }
    } catch (error) {
        console.log(error)
        return {
            success: false,
            error: error.message,
        }
    }
}

export async function ProcessOrderInParse(orderId) {
    var Order = Parse.Object.extend("Order")
    var query = new Parse.Query(Order)
    query.equalTo("objectId", orderId)

    try {
        let returnVal
        //Get the current order and its information
        const order = await query.first()

        const isPaid = order.get("paid")
        const orderId = order.get("orderId")

        //If it's already a paid order the user might have just refreshed their browser
        if (isPaid) {
            returnVal = {
                success: true,
            }
        } else {
            //Set the order as paid, save and complete order in shopify
            order.set("paid", true)
            order.save()

            // const completed = await CompleteOrder(orderId)
            let completed = await axios.post('/.netlify/functions/complete-draft-order' , 
            JSON.stringify({
                orderId: orderId
            }))

            if (completed.data.id) {
                returnVal = {
                    success: true,
                    data: completed,
                }
            } else {
                returnVal = {
                    success: false,
                }
            }
        }
        return returnVal
    } catch (err) {
        return { success: false, err }
    }
}
