import React, { Component } from "react"
import styled from "styled-components"
import Layout from "../components/layout"
import { colours } from "../style/colours"
import { connect } from "react-redux"
import LineItem from "../components/cart-flyout/line-item"
import { toggleShowCart, populateCart } from "../state/app"
import Loader from "react-loader-spinner"
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import { motion } from "framer-motion"
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/bootstrap.css'
import axios from 'axios'
import { CreateOrderInParse } from "../services/parse-bridge"
import { DELIVERY_FEE } from "../project-constants/project-constants"
import SEO from "../components/seo"

const Container = styled.div``
const InnerContainer = styled.div`
  max-width: 1200px;
  padding: 50px 30px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 50% 50%;
  grid-gap: 30px;

  @media (max-width: 900px) {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
  }
`
const Checkout = styled.div`
  @media (max-width: 900px) {
    margin-top: 20px;
  }
`
const Summary = styled.div``
const Title = styled.h1`
  color: ${colours.maroonDark};
`
const CheckoutForm = styled.form`
  display: flex;
  flex-direction: column;
  max-width: 400px;
`
const Heading = styled.h2`
  color: ${colours.greenDark};
`
const Label = styled.label`
  /* text-transform: uppercase; */
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 5px;
  color: ${colours.greenDark};
  font-family: "Muli";
`
const InputGrid = styled.div`
  display: grid;
  grid-template-columns: 40% 60%;
  gap: 5px;
`
const Input = styled.input`
  padding: 10px 10px;
  border: 1px solid #bc5f5230;
  border-radius: 1px;
  margin-bottom: 20px;
  width: 100%;
`
const Textarea = styled.textarea`
  padding: 10px 10px;
  /* border: 1px solid ${colours.maroonDark}; */
  border: 1px solid #bc5f5230;
  border-radius: 1px;
  margin-bottom: 20px;

  :focus {
  border: 1px solid ${colours.maroonDark};

  }
`
const SubmitBtn = styled.button`
  background: ${colours.greenDark};
  color: white;
  padding: 10px;
  width: 100%;
  border: none;
  cursor: pointer;
`
const CartContainer = styled.section`
  /* max-height: 400px; */
  overflow-y: scroll;
  margin-bottom: 30px;
  /* position: sticky; */
  top: 50px;
`
const EmptyText = styled.p`
  text-align: center;
  margin: 0;
  opacity: 0.8;
  font-size: 20px;
`
const Break = styled.hr`
  opacity: 0.3;
`
const TotalCostContainer = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
`
const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;
const Total = styled.p`
  font-size: 20px;
  font-weight: 500;
  opacity: 0.9;
`
const LineTitle = styled.h4`
  margin-bottom: 15px;
`

const CardContainer = styled.div`
  height: calc(100vh - 97px);
  width: 100%;
  background: #f2f2f2;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
`
const Card = styled(motion.div)`
  padding: 30px;
  border-radius: 5px;
  box-shadow: 0 0 7px rgba(118, 118, 118, 0.19);
  max-width: 320px;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;
`
const Logo = styled.img`
  margin: 0 0 30px 0;
  width: 120px;
`
const LoadCopy = styled.p`
  font-weight: 600;
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: 30px;
`
const WiPay = styled.img`
  margin: 0 0 30px 0;
  width: 120px;
`

const RETURN_URL = process.env.NODE_ENV === "development" ? 'http://localhost:8000/verify-transaction' : 'https://gradienthealthfitness.com/verify-transaction' 
const WIPAY_DEVELOPER_ID = process.env.NODE_ENV === "development" ? '1' : process.env.GATSBY_WIPAY_DEVELOPER_ID
const ACTION_URL = process.env.NODE_ENV === "development" ? 'https://sandbox.wipayfinancial.com/v1/gateway' : 'https://wipayfinancial.com/v1/gateway_live'

class CheckoutPage extends Component {
  state = {}
  constructor(props) {
    super(props)
    this.paymentForm = React.createRef()
    this.loadingCard = React.createRef()

    this.state = {
      firstName: "",
      lastName: "",
      emailAddress: "",
      phoneNumber: "",
      address1: "",
      address2: "",
      city: "",
      orderId: "",
      showIntermittentLoader: false,
    }
  }

  componentDidMount() {
    this.checkForExistingCart()
    this.props.dispatch(toggleShowCart(false))

  }

  checkForExistingCart = () => {
    if (this.props.shoppingCart.length === 0) {
      this.getCheckoutFromStorage()
    }
  }

  getCheckoutFromStorage = () => {
    if (typeof window !== 'undefined') {
      // do your checks; this will be re-invoked on client entry
      let data = sessionStorage.getItem('checkout');

      if (data) {
        try {
          let cart = JSON.parse(data)
          this.props.dispatch(populateCart(cart))
  
        }catch(err) {
          console.log("Error parsing")
          console.log(err)
        }
      }

  }
  }

  updateInput = e => {
    this.setState({ [e.target.name]: e.target.value })
  }

  goToWipay = () => {
    this.paymentForm.current.submit()
  }

  handleSubmit = async (e) => {
    e.preventDefault()

    if (!this.isFormValidated()) {
      return
    }

    const {
      firstName,
      lastName,
      emailAddress,
      phoneNumber,
      address1,
      address2,
      city,
    } = this.state

    //Show the intermittent loader
    this.setState({ showIntermittentLoader: true }, () => {
      //Scroll to loader
      this.loadingCard.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
      })
    })

    try {
      let postRes = await axios.post('/.netlify/functions/create-draft-order', 
      JSON.stringify({
          firstName: firstName,
          lastName: lastName,
          email: emailAddress,
          phone: phoneNumber,
          address1: address1,
          address2: address2,
          city: city,
          cart: this.stringifyCart()

      }))
      this.setState({ isLoading: false })
      if (postRes.data.id) {
          //Order was a success
          CreateOrderInParse(
          firstName + " " + lastName,
          emailAddress,
          phoneNumber,
          `${this.renderPrice()}`,
          postRes.data.id,
          ).then(res => {
              if (res.success === true) {
                  //Continue with WiPay Order
                  this.setState({ orderId: res.orderID }, () => {
                      this.goToWipay()
                  })
              } else {
                  alert(
                      "Our servers are currently down. " +
                          res.error +
                          ". Please try your order again"
                  )
              }
          })
      }else {
          alert("Something went wrong with your order. Please try again and contact us if the problem persists.")
      }
  }catch(err){
      alert("Something went wrong with your order. Please try again and contact us if the problem persists.")
  }

    // this.goToWipay()
  }

  isFormValidated = () => {
    const {
      firstName,
      lastName,
      emailAddress,
      phoneNumber,
      address1,
      address2,
      city,
    } = this.state

    let returnVal

    if (
      firstName === "" ||
      lastName === "" ||
      emailAddress === "" ||
      phoneNumber === "" ||
      address1 === "" ||
      city === ""
    ) {
      alert("Please fill all fields. This allows us to better serve you!")
      returnVal = false
    } else {
      returnVal = true
    }

    return returnVal
  }

  stringifyCart = () => {
    const { shoppingCart } = this.props
    const cartForShopify = shoppingCart.map(el => ({
      quantity: el.count,
      variant_id: el.variantId
    }))

    return JSON.stringify(cartForShopify)

  }

  renderPrice = () => {
    const { shoppingCart } = this.props

    const priceAndCount = shoppingCart.map(item => ({
      price: item.priceRange.maxVariantPrice.amount,
      count: item.count,
    }))

    let totals = priceAndCount.map(item => item.count * item.price)

    if (totals.length === 0) {
      totals = [0]
    }

    const reducer = (accumulator, currentValue) => accumulator + currentValue

    const totalPrice = totals.reduce(reducer, DELIVERY_FEE)
    return totalPrice.toFixed(2)
  }

  render() {
    const { shoppingCart } = this.props
    const {
      firstName,
      lastName,
      emailAddress,
      phoneNumber,
      address1,
      address2,
      city,
      showIntermittentLoader,
      orderId
    } = this.state

    if (showIntermittentLoader) {
      return (
        <Layout>
          <CardContainer>
            <Card
              ref={this.loadingCard}
              initial={{ opacity: 0, y: 30, scale: 0.9 }}
              animate={{ opacity: 1, y: 0, scale: 1 }}
              transition={{ duration: 1.4 }}
            >
              <Logo src={require("../images/Gradient Wordmark-08.png")} />
              <LoadCopy>
                We are taking you to our payment provider to complete your
                purchase. Your information is handled securely by WiPay and
                never touches our servers.
              </LoadCopy>
              <WiPay src={require("../images/wipay.png")} />
              <Loader
                type="Oval"
                color={colours.peach}
                height={50}
                width={50}
              />
            </Card>
          </CardContainer>
          <form
                style={{ marginBottom: 0 }}
                action={ACTION_URL}
                method="post"
                ref={this.paymentForm}
              >
                <input name="total" type="hidden" value={this.renderPrice()} />
                <input name="phone" type="hidden" value={phoneNumber} />
                <input
                  name="email"
                  type="hidden"
                  value={emailAddress}
                />
                <input name="name" type="hidden" value={firstName + " " + lastName} />
                <input name="order_id" type="hidden" value={orderId} />
                <input
                  name="return_url"
                  type="hidden"
                  value={RETURN_URL}
                />
                <input name="developer_id" type="hidden" value={WIPAY_DEVELOPER_ID} />
              </form>
        </Layout>
      )
    }

    return (
      <Layout>
        <SEO title="Checkout" />
        <Container>
          <InnerContainer>
            <Checkout>
              <Title>Checkout</Title>
              <Heading>Billing and Contact Information</Heading>
              <CheckoutForm onSubmit={this.handleSubmit}>
                <InputGrid>
                  <div>
                    <Label>First Name</Label>
                    <Input
                      type="text"
                      value={firstName}
                      name="firstName"
                      placeholder="Mary Smith"
                      onChange={this.updateInput}
                    />
                  </div>
                  <div>
                    <Label>Last Name</Label>
                    <Input
                      type="text"
                      value={lastName}
                      name="lastName"
                      placeholder="Mary Smith"
                      onChange={this.updateInput}
                    />
                  </div>
                </InputGrid>

                <Label>Email Address</Label>
                <Input
                  type="text"
                  name="emailAddress"
                  value={emailAddress}
                  placeholder="you@example.com"
                  onChange={this.updateInput}
                />

                <Label>Phone Number</Label>
                <PhoneInput
                            value={phoneNumber}
                            onChange={phoneNumber => this.setState({ phoneNumber })}
                            onFocus={() => this.setState({ phoneActive: true })}
                            onBlur={() => this.setState({ phoneActive: false })}
                            preferredCountries={['tt']}
                            country={'tt'}
                            enableAreaCodes={true}
                            // autoFormat={false}
                            enableAreaCodes={true}
                            masks={{tt: '...-....'}}
                            enableLongNumbers={true}
                            areaCodes={{tt: ['868']}}
                            inputStyle={{width: '100%', fontSize: 17, height: 52, borderRadius: 0, border: '1px solid #bc5f5230'}}
                            containerStyle={{marginBottom: 20}}
                            inputClass="phoneInputStyle"
                            countryCodeEditable={true}
                        />
                {/* <Input
                  type="text"
                  name="phoneNumber"
                  value={phoneNumber}
                  placeholder="868 123 4567"
                  onChange={this.updateInput}
                /> */}

                <Label>Address Line 1</Label>
                <Input
                  value={address1}
                  name="address1"
                  placeholder="420 High Street"
                  onChange={this.updateInput}
                />

                <Label>Address Line 2</Label>
                <Input
                  value={address2}
                  name="address2"
                  placeholder="Pembrook Road"
                  onChange={this.updateInput}
                />

                <Label>City</Label>
                <Input
                  value={city}
                  type="text"
                  name="city"
                  placeholder="Diego Martin"
                  onChange={this.updateInput}
                />

                <SubmitBtn>Continue to Payment</SubmitBtn>
              </CheckoutForm>
              <form
                style={{ marginBottom: 0 }}
                action="https://sandbox.wipayfinancial.com/v1/gateway"
                method="post"
                ref={this.paymentForm}
              >
                <input name="total" type="hidden" value={this.renderPrice()} />
                <input name="phone" type="hidden" value={phoneNumber} />
                <input
                  name="email"
                  type="hidden"
                  value={emailAddress}
                />
                <input name="name" type="hidden" value={firstName + " " + lastName} />
                <input name="order_id" type="hidden" value={orderId} />
                <input
                  name="return_url"
                  type="hidden"
                  value={RETURN_URL}
                />
                <input name="developer_id" type="hidden" value="1" />
              </form>
            </Checkout>
            <Summary>
              {shoppingCart.length > 0 ? (
                <>
                  <CartContainer key={shoppingCart}>
                    {shoppingCart.map((item, index) => (
                      <div key={index}>
                        <LineItem
                          key={index}
                          product={item}
                          display
                          removeItem={this.handleRemoveItem}
                          addToCount={this.handleAddToCount}
                          minusFromCount={this.handleMinusFromCount}
                        />
                        <Break />
                      </div>
                    ))}
                  </CartContainer>

                  <TotalCostContainer>
                    <Row>
                      <LineTitle>Subtotal</LineTitle>
                      <Total>${this.renderPrice() - DELIVERY_FEE}TTD</Total>
                    </Row>
                    <Row>
                      <LineTitle>Delivery</LineTitle>
                      <Total>${DELIVERY_FEE.toFixed(2)}TTD</Total>
                    </Row>
                    <Row>
                      <LineTitle>Total</LineTitle>
                      <Total>${this.renderPrice()}TTD</Total>

                    </Row>
                  </TotalCostContainer>
                </>
              ) : (
                <EmptyText>Your cart is empty.</EmptyText>
              )}
            </Summary>
          </InnerContainer>
        </Container>
      </Layout>
    )
  }
}

export default connect(
  state => ({
    shoppingCart: state.app.shoppingCart,
  }),
  null
)(CheckoutPage)
